import React, { Component } from 'react';
import CSVReader from 'react-csv-reader';
import PopUpComponent from './PopUpComponent';
import Papa from 'papaparse';
import $ from 'jquery';
import axios from 'axios';
import { importUserAPIURL } from '../../common-utils/api_urls';
import { importUserAPICall } from '../../services/getUserDetail';
import { getCSVTamplateUrl } from '../../apiconfig';
import StyledDropzone from './StyledDropzone';
import * as translationUtils from '../../common-utils/translation_utils';
class ImportUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isCSVFileValid: true,
      legalIds: null,
      message: '',
      status: '',
      isDisable: true,
      isReset: false,
      invalidRows: null,
      verifyCustId: [],
      isChecked: false,
      messageAlert: '',
    };
    this.updateData = this.updateData.bind(this);
    //this.setResponse=this.setResponse.bind(this)
  }
  componentWillMount() {}

  setResponse = (message, status) => {
    //console.log(message+" "+status);
    this.setState({ message: message, status: status });
    setTimeout(() => {
      this.setState({ message: '' });
    }, 5000);
  };
  resetFile = (e) => {
    this.setState({
      csvFile: null,
      isReset: true,
      status: '',
      invalidRows: null,
      verifyCustId: [],
      isChecked: false,
      isDisable: true,
    });
  };
  componentDidMount() {
    let arr = [];
    this.props.orgList &&
      this.props.orgList.map((item) =>
        item.isAdmin ? arr.push(item.legalId) : []
      );
    this.setState({
      legalIds: arr,
    });
  }

  validateFile(index, idx, mess) {
    this.setState({
      isCSVFileValid: false,
      invalidRows: { row: index + 1, column: idx + 1 },
      messageAlert: mess,
      isDisable: false,
      // isChecked: false,
      // status: '',
    });
    //console.log('Inside Valdiate', index + '-' + idx + '-' + mess);
  }

  importCSV() {
    const { csvFile } = this.state;
    //console.log("isCSVFileValid123", csvFile.name);

    let reader = new FileReader();
    let that = this;
    // console.log("isCSVFileValid",this.state.isCSVFileValid)
    if (this.state.isCSVFileValid && this.state.csvFile != null) {
      reader.onload = function () {
        // console.log("FileReader",reader.result);
        let url = importUserAPIURL(sessionStorage.getItem('oktaUserEmail'));
        importUserAPICall(that, url, reader.result);
      };

      reader.readAsBinaryString(this.state.csvFile);
    } else {
      if (this.state.isCSVFileValid == false || this.state.csvFile == null) {
        console.log("iscsvfile==",this.state.isCSVFileValid)
        if (this.state.csvFile == null)
          this.setState({
            message: translationUtils.getTranslation(
              2225,
              'Please add a file to upload'
            ),
            status: 'Failed',
          });
        else if (!this.state.csvFile.name.endsWith('.csv') || !this.state.csvFile.name.endsWith('.CSV'))
          this.setState({
            message: translationUtils.getTranslation(
              2202,
              'Please upload a valid file'
            ),
            status: 'Failed',
          });
        else if (this.state.isCSVFileValid == false)
          this.setState({
            message: translationUtils.getTranslation(
              2203,
              'Invalid Users found, Please validate  the Users in your CSV'
            ),
            status: 'Failed',
          });
        else
          this.setState({
            message: translationUtils.getTranslation(
              2204,
              'Sorry, something went wrong !'
            ),
            status: 'Failed',
          });
      }
      setTimeout(() => {
        this.setState({ message: '' });
      }, 5000);
    }
  }
  updateData(data) {
    //console.log("csv data",data)
    this.fileOnLoad(data.data);
  }
  fileReader = (event) => {
    this.setState({
      isCSVFileValid: true,
      message: '',
      status: '',
      isReset: false,
    });

    //console.log('files', event);
    this.setState({ csvFile: event });
    Papa.parse(event, {
      complete: this.updateData,
      header: false,
      keepEmptyRows: false,
      skipEmptyLines: true,
    });
  };

  fileOnLoad(data) {
    const legalIdArr = this.state.legalIds;
    //console.log('LegalId', this.state.isCSVFileValid);
    if (this.state.csvFile.name.endsWith('.csv')) {
      if (
        data[0][0] !== 'Email' ||
        data[0][1] !== 'FirstName' ||
        data[0][2] !== 'LastName' ||
        data[0][3] !== 'CustomerID' ||
        data[0][4] !== 'Access'
      ) {
        this.validateFile(
          -1,
          -1,
          'Headers should be correct as "Email,FirstName,LastName,CustomerID,Access"'
        );
      } else {
        data.map((row, index) =>
          row[0].toLowerCase() != 'email' && index !== 0
            ? row.map((column, idx) => {
                column = column.trim();
                //console.log('Column', column);
                if (column == null || column == '') {
                  this.validateFile(index, idx, 'Data Column cannot be blank');
                } else if (idx == 3 && !legalIdArr.includes(column)) {
                  if (!this.state.verifyCustId.includes(column)) {
                    this.state.verifyCustId.push(column);
                    //this.setState({ status: 'Failed' });
                  }
                  this.validateFile(
                    index,
                    idx,
                    'You do not have authorization to the following customer number(s):'
                  );
                } else if (row.length < 4) {
                  this.validateFile(index, idx, 'Data Column Issue');
                } else if (idx === 0) {
                  if (
                    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                      column
                    ) === false
                  ) {
                    this.validateFile(index, idx, 'Email Id is incorrect');
                  }
                } else {
                }
              })
            : ''
        );
      }
    } else {
      this.setState({ isCSVFileValid: false });
    }
  }
  handleCheckBox = (checked) => {
    this.setState({ isChecked: checked });
  };
  render() {
    //console.log('Invlaid Rows', this.state.invalidRows);
    //console.log('Count', this.state.verifyCustId.length);
    //  console.log(this.props.userInfo)
    return (
      <div>
        <div
          className="ph-modal fade"
          id="importuserModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="Import User Modal"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="ph-modal__content">
              <div className="d-flex justify-content-end m-0 p-0">
                <span
                  data-dismiss="modal"
                  aria-label="Close"
                  className="ph-modal__header--close ph-modal-header-close-font-size"
                  aria-hidden="true"
                  onClick={(e) => this.resetFile(e)}
                >
                  <i className="fas fa-times"></i>
                </span>
              </div>
              <div className="ph-modal__header">
                <h3 className="ph-modal__title" id="importuserModalLabel">
                  {translationUtils.getTranslation(
                    2193,
                    'Import Users from CSV'
                  )}
                </h3>
              </div>
              <div className="ph-modal__body">
                <div>
                  {translationUtils.getTranslation(
                    2194,
                    'Create multiple users by importing CSV file.'
                  ) +
                    ' ' +
                    translationUtils.getTranslation(2226, 'Please')}
                  ,
                  {/* <a href={getCSVTamplateUrl()} className="ph-blue-link" target="_blank"> Download the template.</a> if you need. */}
                  <a
                    href={'/assets/templates/User_CSV_Sample_download.csv'}
                    className="ph-blue-link"
                    target="_blank"
                  >
                    {' '}
                    {translationUtils.getTranslation(
                      2195,
                      'Download the template.'
                    )}
                  </a>{' '}
                  {translationUtils.getTranslation(2196, 'if you need.')}
                </div>
                <div className="row ph-form-import-row">
                  {/* <div className="col-12 col-sm-8">
                                        <label className="ph-form-row__label" htmlFor="quantity">CSV File</label>
                                        <input className="ph-form-row__input" type="file" id="csvfile" onChange={(e)=> this.fileReader(e) } onClick={(e)=> this.resetFile(e)}/>
                                        
                                    </div> */}
                  <StyledDropzone
                    csvFile={this.state.csvFile}
                    isReset={this.state.isReset}
                    fileReader={this.fileReader}
                    resetFile={this.resetFile}
                    message={this.state.message}
                    status={this.state.status}
                    handleCheckBox={this.handleCheckBox}
                    isChecked={this.state.isChecked}
                    isDisable={this.state.isDisable}
                  />

                  {/* <div className="col-6 col-sm-4 padding-browse">
                                    
                                        <button type="file" className="button round-button small-button">Browse</button>
                                        
                                    </div>                   */}
                </div>
                {/* {
                  !this.state.isCSVFileValid(
                    <div className="alert alert-danger text-dark" role="alert">
                      <div>
                        You do not have authorization to upload file apart from
                        CSV
                      </div>
                    </div>
                  )
                } */}
                {this.state.invalidRows && (
                  <div className="alert alert-danger text-dark" role="alert">
                    <div>
                      {this.state.messageAlert
                        ? this.state.messageAlert
                        : translationUtils.getTranslation(
                            2201,
                            'You do not have authorization to the following customer number(s):'
                          )}

                      {this.state.verifyCustId.toString()}
                    </div>
                    <div>
                      {' '}
                      Invalid Record Found at Row No.{' '}
                      {this.state.invalidRows.row} and Column No.{' '}
                      {this.state.invalidRows.column}
                    </div>
                  </div>
                )}
              </div>
              <div className="ph-modal__footer">
                {this.state.message != '' && this.state.status != 'Success' && (
                  <PopUpComponent
                    status={this.state.status}
                    message={this.state.message}
                  />
                )}
                <button
                  type="button"
                  className="special_buttonn button accent-button round-button medium-button small-button"
                  data-dismiss="modal"
                  onClick={(e) => this.resetFile(e)}
                >
                  {translationUtils.getTranslation(676, 'Close')}
                </button>{' '}
                &nbsp;
                {(this.state.status != 'Success' &&
                  this.state.csvFile == null) ||
                this.state.isChecked === false ? (
                  <button
                    type="button"
                    className="special_buttonn button primary-button round-button medium-button small-button"
                    style={{ opacity: '0.5' }}
                  >
                    {translationUtils.getTranslation(2227, 'Upload')}
                  </button>
                ) : this.state.verifyCustId &&
                  this.state.verifyCustId.length > 0 ? (
                  <button
                    type="button"
                    className="special_buttonn button primary-button round-button medium-button small-button"
                    style={{ opacity: '0.5' }}
                  >
                    {translationUtils.getTranslation(2227, 'Upload')}
                  </button>
                ) : (
                  <button
                    type="button"
                    className={
                      this.state.status == 'Success'
                        ? 'button_off'
                        : 'special_buttonn button primary-button round-button medium-button small-button'
                    }
                    onClick={() => this.importCSV()}
                  >
                    {translationUtils.getTranslation(2227, 'Upload')}
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default ImportUser;
