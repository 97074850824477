import axios from 'axios';
import * as apis from '../common-utils/api_urls';
import * as translationUtils from '../common-utils/translation_utils';
import {
  getAPIAuthKey,
  getHeaderSubKey,
  getHeaderSubKeyForApplicationRole,
} from '../apiconfig';
import langData from '../common-utils/languages.json';

/**
 * Get Application Tiles Data Service
 * @param {MainWrapper} callingClass
 * @param {string} apiUrl
 * @param {string} authToken
 */
export default function getUserDetail(callingClass, apiUrl) {
  //let customizeHeader={}
  let headers = {
    'Ocp-Apim-Subscription-Key': getAPIAuthKey(),
    'Cache-Control': 'no-cache',
    Accept: 'application/json',
    WCToken: window.sessionStorage.getItem('WCToken'),
    WCTrustedToken: window.sessionStorage.getItem('WCTrustedToken'),
  };
  if (headers.WCToken == null || headers.WCTrustedToken == null) {
    headers = {
      'Ocp-Apim-Subscription-Key': getAPIAuthKey(),
      'Cache-Control': 'no-cache',
      Accept: 'application/json',
    };
  }

  // customizeHeader.Accept='application/json'
  //if(window.location.origin.indexOf('myparkerdev')>0)
  // customizeHeader.Authorization= 'Basic '+btoa("phstage:phstage")
  const res = axios(apiUrl, {
    method: 'GET',
    headers: headers,
  })
    .then((result) => {
      if (result.data !== null && result.data !== undefined) {
        callingClass.setUserDataInfo(result.data);
      } else {
        sessionStorage.clear();
        //window.location.href=`${window.allData.loginUrl}${window.location.href}`;
      }
    })
    .catch((error) => {
      sessionStorage.clear();
      // window.location.href=`${window.allData.loginUrl}${window.location.href}`
    });
}

/**
 * Get Application Tiles Data Service
 * @param {MainWrapper} callingClass
 * @param {string} apiUrl
 * @param {string} authToken
 */
export function getOktaLoginHistory(callingClass, apiUrl) {
  //let customizeHeader={}
  let headers = {
    'Ocp-Apim-Subscription-Key': getAPIAuthKey(),
    'Cache-Control': 'no-cache',
    Accept: 'application/json',
    WCToken: window.sessionStorage.getItem('WCToken'),
    WCTrustedToken: window.sessionStorage.getItem('WCTrustedToken'),
  };
  if (headers.WCToken == null || headers.WCTrustedToken == null) {
    headers = {
      'Ocp-Apim-Subscription-Key': getAPIAuthKey(),
      'Cache-Control': 'no-cache',
      Accept: 'application/json',
    };
  }

  // customizeHeader.Accept='application/json'
  //if(window.location.origin.indexOf('myparkerdev')>0)
  //customizeHeader.Authorization= 'Basic '+btoa("phstage:phstage")
  const res = axios(apiUrl, {
    method: 'GET',
    headers: headers,
  })
    .then((result) => {
      if (result.data !== null && result.data !== undefined) {
        callingClass.setLoginHistory(result.data);
      } else {
        sessionStorage.clear();
        //window.location.href=`${window.allData.loginUrl}${window.location.href}`;
      }
    })
    .catch((error) => {
      sessionStorage.clear();
      // window.location.href=`${window.allData.loginUrl}${window.location.href}`
    });
}

export function ActivateUser(callingClass, apiUrl, locationID) {
  //let customizeHeader={}
  let headers = {
    'Ocp-Apim-Subscription-Key': getAPIAuthKey(),
    'Cache-Control': 'no-cache',
    Accept: 'application/json',
    WCToken: window.sessionStorage.getItem('WCToken'),
    WCTrustedToken: window.sessionStorage.getItem('WCTrustedToken'),
  };
  if (headers.WCToken == null || headers.WCTrustedToken == null) {
    headers = {
      'Ocp-Apim-Subscription-Key': getAPIAuthKey(),
      'Cache-Control': 'no-cache',
      Accept: 'application/json',
    };
  }

  // customizeHeader.Accept='application/json'

  //customizeHeader.Accept='application/json'
  // if(window.location.origin.indexOf('myparkerdev')>0)
  //customizeHeader.Authorization= 'Basic '+btoa("phstage:phstage")

  axios(apiUrl, {
    method: 'POST',
    withCredentials: false,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Ocp-Apim-Subscription-Key': getAPIAuthKey(),
      'Cache-Control': 'no-cache',
      //'oktaSessionId': window.sessionStorage.getItem('oktaSessionId')
      WCToken: window.sessionStorage.getItem('WCToken'),
      WCTrustedToken: window.sessionStorage.getItem('WCTrustedToken'),
      //customizeHeader
    },
    data: locationID,
  })
    .then(function (result) {
      console.log('EDIT user Data ', result);
      callingClass.setAPIResponose(
        result.status,
        result.data.message,
        result.data.profileUpdated
      );
    })
    .catch(function (error) {
      console.log('ERROR WHILE AUTHENTICATING');
      callingClass.setAPIResponose(error, '');
      console.log(error);
    });
}

export function getAssignLocationsAPI(callingClass, apiUrl) {
  //let customizeHeader={}
  let headers = {
    'Ocp-Apim-Subscription-Key': getAPIAuthKey(),
    'Cache-Control': 'no-cache',
    Accept: 'application/json',
    WCToken: window.sessionStorage.getItem('WCToken'),
    WCTrustedToken: window.sessionStorage.getItem('WCTrustedToken'),
  };

  if (headers.WCToken == null || headers.WCTrustedToken == null) {
    headers = {
      'Ocp-Apim-Subscription-Key': getAPIAuthKey(),
      'Cache-Control': 'no-cache',
      Accept: 'application/json',
    };
  }

  // customizeHeader.Accept='application/json'
  //if(window.location.origin.indexOf('myparkerdev')>0)
  //customizeHeader.Authorization= 'Basic '+btoa("phstage:phstage")
  const res = axios(apiUrl, {
    method: 'GET',
    headers: headers,
  })
    .then((result) => {
      if (result.data !== null && result.data !== undefined) {
        callingClass.setLocations(result.data);
      } else {
        // sessionStorage.clear();
        //window.location.href=`${window.allData.loginUrl}${window.location.href}`;
      }
    })
    .catch((error) => {
      //sessionStorage.clear();
      // window.location.href=`${window.allData.loginUrl}${window.location.href}`
    });
}

export function saveRemoveLocation(callingClass, apiUrl, locations) {
  //let customizeHeader={}
  let headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Ocp-Apim-Subscription-Key': getAPIAuthKey(),
    'Cache-Control': 'no-cache',
    //'oktaSessionId': window.sessionStorage.getItem('oktaSessionId')
    WCToken: window.sessionStorage.getItem('WCToken'),
    WCTrustedToken: window.sessionStorage.getItem('WCTrustedToken'),
    //customizeHeader
  };

  if (headers.WCToken == null || headers.WCTrustedToken == null) {
    headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Ocp-Apim-Subscription-Key': getAPIAuthKey(),
      'Cache-Control': 'no-cache',
    };
  }

  // customizeHeader.Accept='application/json'
  //if(window.location.origin.indexOf('myparkerdev')>0)
  //customizeHeader.Authorization= 'Basic '+btoa("phstage:phstage")

  axios(apiUrl, {
    method: 'POST',
    withCredentials: false,
    headers: headers,
    data: locations,
  })
    .then(function (result) {
      console.log(result);
      callingClass.setAPIResponose(result.status, result.data.message);
    })
    .catch(function (error) {
      //console.log('ERROR WHILE AUTHENTICATING');
      callingClass.setAPIResponose(error, error);
      console.log(error);
    });
}

export function AssignRolesData(callingClass, apiUrl) {
  //let customizeHeader={}
  let headers = {
    'Ocp-Apim-Subscription-Key': getAPIAuthKey(),
    'Cache-Control': 'no-cache',
    Accept: 'application/json',
    WCToken: window.sessionStorage.getItem('WCToken'),
    WCTrustedToken: window.sessionStorage.getItem('WCTrustedToken'),
  };
  if (headers.WCToken == null || headers.WCTrustedToken == null) {
    headers = {
      'Ocp-Apim-Subscription-Key': getAPIAuthKey(),
      'Cache-Control': 'no-cache',
      Accept: 'application/json',
    };
  }

  // customizeHeader.Accept='application/json'
  //if(window.location.origin.indexOf('myparkerdev')>0)
  // customizeHeader.Authorization= 'Basic '+btoa("phstage:phstage")
  const res = axios(apiUrl, {
    method: 'GET',
    headers: headers,
  })
    .then((result) => {
      if (result.data !== null && result.data !== undefined) {
        callingClass.setUserData(result.data);
      } else {
        // sessionStorage.clear();
        //window.location.href=`${window.allData.loginUrl}${window.location.href}`;
      }
    })
    .catch((error) => {
      //sessionStorage.clear();
      // window.location.href=`${window.allData.loginUrl}${window.location.href}`
    });
}

// Add User Post request
export function AddUserDetail(callingClass, apiUrl, payload) {
  //let customizeHeader={}
  let headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Ocp-Apim-Subscription-Key': getAPIAuthKey(),
    'Cache-Control': 'no-cache',
    WCToken: window.sessionStorage.getItem('WCToken'),
    WCTrustedToken: window.sessionStorage.getItem('WCTrustedToken'),
  };
  if (headers.WCToken == null || headers.WCTrustedToken == null) {
    headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Ocp-Apim-Subscription-Key': getAPIAuthKey(),
      'Cache-Control': 'no-cache',
    };
  }

  // customizeHeader.Accept='application/json'
  // if(window.location.origin.indexOf('myparkerdev')>0)
  //customizeHeader.Authorization= 'Basic '+btoa("phstage:phstage")

  axios(apiUrl, {
    method: 'POST',
    withCredentials: false,
    headers: headers,
    data: payload,
  })
    .then(function (result) {
      // console.log(result)
      if (result.status == 200) {
        callingClass.setMessage(
          result.status,
          result.data.message === 'Changes saved successfully.'
            ? translationUtils.getTranslation(
                2190,
                'Changes saved successfully.'
              )
            : result.data.message
        );
      } else {
        callingClass.setMessage(
          result.status,
          translationUtils.getTranslation(2204, 'Sorry, something went wrong')
        );
      }
    })
    .catch(function (error) {
      //console.log('ERROR WHILE AUTHENTICATING');
      //callingClass.setMessage("ERROR WHILE AUTHENTICATING")
      //console.log(error);
      callingClass.setMessage(
        'Failed',
        translationUtils.getTranslation(2204, 'Sorry, something went wrong')
      );
    });
}

export function ResetMaill(callingClass, apiUrl) {
  //let customizeHeader={}
  let headers = {
    'Ocp-Apim-Subscription-Key': getAPIAuthKey(),
    'Cache-Control': 'no-cache',
    Accept: 'application/json',
    WCToken: window.sessionStorage.getItem('WCToken'),
    WCTrustedToken: window.sessionStorage.getItem('WCTrustedToken'),
  };
  if (headers.WCToken == null || headers.WCTrustedToken == null) {
    headers = {
      'Ocp-Apim-Subscription-Key': getAPIAuthKey(),
      'Cache-Control': 'no-cache',
      Accept: 'application/json',
    };
  }
  const res = axios(apiUrl, {
    method: 'GET',
    headers: headers,
  })
    .then((result) => {
      if (result.data !== null && result.data !== undefined) {
        console.log('result', result);
        callingClass.setAPIResponose(result.status, result.data.message);
      } else {
        console.log('result', result);
      }
    })
    .catch((error) => {
      if (error.request.status >= 400 && error.request.status < 500) {
        //callingClass.setAPIResponose(error.request.status, JSON.parse(error.request.responseText).errorSummary)
        callingClass.setAPIResponose(
          error.request.status,
          'Error occured, Please try again!'
        );
      } else {
        callingClass.setAPIResponose(
          error.request.status,
          'Error occured, Please try again!'
        );
      }
    });
}

export function PasswordResetMail(callingClass, apiUrl) {
  //let customizeHeader={}
  let headers = {
    'Ocp-Apim-Subscription-Key': getAPIAuthKey(),
    'Cache-Control': 'no-cache',
    Accept: 'application/json',
    WCToken: window.sessionStorage.getItem('WCToken'),
    WCTrustedToken: window.sessionStorage.getItem('WCTrustedToken'),
  };
  if (headers.WCToken == null || headers.WCTrustedToken == null) {
    headers = {
      'Ocp-Apim-Subscription-Key': getAPIAuthKey(),
      'Cache-Control': 'no-cache',
      Accept: 'application/json',
    };
  }

  // customizeHeader.Accept='application/json'
  // if(window.location.origin.indexOf('myparkerdev')>0)
  //customizeHeader.Authorization= 'Basic '+btoa("phstage:phstage")
  const res = axios(apiUrl, {
    method: 'GET',
    headers: headers,
  })
    .then((result) => {
      if (result.data !== null && result.data !== undefined) {
        //console.log('result', result);
        //callingClass.setTemporaryPassword(result.data);
        callingClass.setAPIResponose(result.status, result.data.status);
      } else {
        console.log('result', result);
      }
    })
    .catch((error) => {
      if (error.request.status >= 400 && error.request.status < 500) {
        //callingClass.setAPIResponose(error.request.status, JSON.parse(error.request.responseText).errorSummary)
        callingClass.setAPIResponose(
          error.request.status,
          'Forgot password not allowed on specified user.'
        );
      } else {
        callingClass.setAPIResponose(
          error.request.status,
          'Error occured, Please try again!'
        );
      }
    });
}

export function PasswordResetTemporary(callingClass, apiUrl) {
  //let customizeHeader={}
  let headers = {
    'Ocp-Apim-Subscription-Key': getAPIAuthKey(),
    'Cache-Control': 'no-cache',
    Accept: 'application/json',
    WCToken: window.sessionStorage.getItem('WCToken'),
    WCTrustedToken: window.sessionStorage.getItem('WCTrustedToken'),
  };
  if (headers.WCToken == null || headers.WCTrustedToken == null) {
    headers = {
      'Ocp-Apim-Subscription-Key': getAPIAuthKey(),
      'Cache-Control': 'no-cache',
      Accept: 'application/json',
    };
  }

  // customizeHeader.Accept='application/json'
  // if(window.location.origin.indexOf('myparkerdev')>0)
  // customizeHeader.Authorization= 'Basic '+btoa("phstage:phstage")
  const res = axios(apiUrl, {
    method: 'GET',
    headers: headers,
  })
    .then((result) => {
      if (result.data !== null && result.data !== undefined) {
        callingClass.setAPIResponose(result.status, result.data.status);
        callingClass.setTemporaryPassword(result.data);
        console.log('result', result);
      } else {
        // sessionStorage.clear();
        //window.location.href=`${window.allData.loginUrl}${window.location.href}`;
      }
    })
    .catch((error) => {
      if (error.request.status >= 400 && error.request.status < 500) {
        //callingClass.setAPIResponose(error.request.status, JSON.parse(error.request.responseText).errorSummary)
        callingClass.setAPIResponose(
          error.request.status,
          translationUtils.getTranslation(
            2212,
            'Temporary password not allowed on specified user.'
          )
        );
      } else {
        callingClass.setAPIResponose(
          error.request.status,
          translationUtils.getTranslation(
            2213,
            'Error occured, Please try again!.'
          )
        );
      }
      //sessionStorage.clear();
      // window.location.href=`${window.allData.loginUrl}${window.location.href}`
    });
}

export function importUserAPICall(callingClass, url, formdata) {
  let headers = {
    'Content-Type': 'text/csv',
    'Ocp-Apim-Subscription-Key': getAPIAuthKey(),
    'Cache-Control': 'no-cache',
    WCToken: window.sessionStorage.getItem('WCToken'),
    WCTrustedToken: window.sessionStorage.getItem('WCTrustedToken'),
  };
  if (headers.WCToken == null || headers.WCTrustedToken == null) {
    headers = {
      'Content-Type': 'text/csv',
      'Ocp-Apim-Subscription-Key': getAPIAuthKey(),
      'Cache-Control': 'no-cache',
      Accept: 'application/json',
    };
  }
  axios({
    method: 'post',
    url: url,
    data: formdata,
    headers: headers,
  })
    .then(function (response) {
      //handle success
      console.log(response);
      callingClass.setResponse(response.data.message, 'Success');
    })
    .catch(function (response) {
      //handle error
      console.log(response);
      callingClass.setResponse(
        translationUtils.getTranslation(2204, 'Sorry, something went wrong'),
        'Failed'
      );

      // this.setState({message: "Please validate uplaoded CSV", status: "Failed"})
    });
}

export async function HeaderFooterJsonTemplates(
  callingClass,
  userId,
  langCode,
  cID
) {
  let headers = {
    'Ocp-Apim-Subscription-Key': getHeaderSubKey(),
    'Cache-Control': 'no-cache',
    Accept: 'application/json',
  };
  try {
    const res = await axios(
      apis.getHeaderFooterJsonTemplateURL(userId, langCode, cID),
      {
        method: 'GET',
        headers: headers,
      }
    );
    if (res.status == 200) {
      // test for status you want, etc
      console.log(res.status);
    }
    // Don't forget to return something
    return res.data;
  } catch (error) {
    console.error(error);
  }
}

export async function getUserDefaultLanguage() {
  let headers = {
    'Content-Type': 'text/csv',
    'Ocp-Apim-Subscription-Key': getAPIAuthKey(),
    'Cache-Control': 'no-cache',
    // WCToken: window.sessionStorage.getItem('WCToken'),
    // WCTrustedToken: window.sessionStorage.getItem('WCTrustedToken'),
  };
  try {
    const res = await axios(apis.getWCSDefaultLanguageURL(), {
      method: 'GET',
      headers: headers,
    });
    console.log("resdata",res.data)
    
    if (res.data && res.data?.langID) {
        const defaultLang = res.data?.langID;
        window.sessionStorage.setItem("firstLogin", defaultLang);
      console.log("deflang", defaultLang)
      if (defaultLang !== null && defaultLang !== undefined && defaultLang !== '') {
        return langData[
          defaultLang
        ].toUpperCase();
      } else {
        return 'EN';
      }
      //return langData[res.data.contextAttribute[0].attributeValue[0].value[0]].toUpperCase();
    } else {
      return 'EN';
    }
  } catch (error) {
    console.error(error);
    return 'EN';
  }
}

//Setting the API Call for Fetching and Setting up the  the Rolesased Access for USed
export function AssignApplicationBasedAccess(callingClass) {
  // let data = JSON.stringify({
  //   EmailAddress: JSON.parse(localStorage.getItem('userDetails')).email,
  //   UserLocation: 'C73641',
  //   FirstName: JSON.parse(localStorage.getItem('userDetails')).firstName,
  //   LastName: JSON.parse(localStorage.getItem('userDetails')).lastName,
  // });
  let data = '';
  let config = {
    method: 'get',
    maxBodyLength: Infinity,
    url:
      apis.getRolesBasedAccess() +
      JSON.parse(localStorage.getItem('userDetails')).email,
    headers: {
      'ocp-apim-subscription-key': getHeaderSubKeyForApplicationRole(),
      'Content-Type': 'application/json',
    },
    data: data,
  };
  axios
    .request(config)
    .then((response) => {
      console.log(JSON.stringify(response.data));
      if (response.data !== null && response.data !== undefined) {
        callingClass.getApplicationBasedRolesCallBack(response.data);
      }
    })
    .catch((error) => {
      console.log(error);
    });
}

export function saveApplicationBasedAccess(callingClass, event, oktaUserIDP) {
  //find substring by hyphen  '-' from event target value to get selected role name

  let evntArr = event[0].split('-');
  let WebResourceIDVal = evntArr[0];
  let RecordStatusCode = evntArr[1];
  let data = JSON.stringify([
    {
      //EmailAddress: JSON.parse(localStorage.getItem('userDetails')).email,
      RecordStatusCode: RecordStatusCode,
      WebResourceID: WebResourceIDVal,
    },
  ]);

  let config = {
    method: 'post',
    maxBodyLength: Infinity,
    url:
      apis.setRolesBasedAccess() +
      'EmailAddress=' +
      JSON.parse(localStorage.getItem('userDetails')).email +
      '&IDPID=' +
      oktaUserIDP +
      '&UserLocation=' +
      window.sessionStorage
        .getItem('orgListWithName')
        .split('(')[1]
        .replace(/[()]/g, '') +
      '&FirstName=' +
      JSON.parse(localStorage.getItem('userDetails')).firstName +
      '&LastName=' +
      JSON.parse(localStorage.getItem('userDetails')).lastName,
    headers: {
      'ocp-apim-subscription-key': getHeaderSubKeyForApplicationRole(),
      'Content-Type': 'application/json',
    },
    data: data,
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
      //console.log('Calls Executed', JSON.stringify(response.data));
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
}

//Setting the API Call for Fetching and Setting up the  the Rolesased Access for USed
